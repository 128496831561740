import { useRouter } from 'next/router'
import { Dispatch, useEffect, useReducer, useRef, useState } from 'react'

export const useClickOutside = (
  open: boolean,
  ref: React.RefObject<HTMLElement>,
  handler: (e?: any) => void
): void => {
  useEffect(() => {
    const listener = (e: MouseEvent): void => {
      if (!ref.current || ref.current.contains(e.target as Node)) {
        return
      }
      handler(e)
    }
    if (open) {
      document.addEventListener('click', listener)
    } else {
      document.removeEventListener('click', listener)
    }
    return () => {
      document.removeEventListener('click', listener)
    }
  }, [ref, handler, open])
}

export const useUserLanguageRoute = () => {
  const router = useRouter()

  useEffect(() => {
    const {
      locales = [],
      locale,
      asPath,
      defaultLocale,
      pathname,
      query,
      isReady,
    } = router

    const browserLanguage = window.navigator.language.slice(0, 2)

    const shouldChangeLocale =
      isReady &&
      locale !== browserLanguage &&
      locale === defaultLocale &&
      locales.includes(browserLanguage)

    if (shouldChangeLocale) {
      router.push(
        {
          pathname,
          query,
        },
        asPath,
        { locale: browserLanguage }
      )
    }
  }, [router])
}

interface TimerConfig {
  offer: Record<string, any>
  timerName: string
  extraTimerName: string
  timeout: number
  extraTimeout: number
  disabled?: boolean | ''
  clearTimer?: boolean
}

interface Result {
  counter: number
  continueDiscountCounter: (sec: number) => void
  discountActivated: boolean
  extraDiscount: boolean
  handleActivateExtraDiscount: () => void
  disabled?: boolean
}

export const useTimer = (config: TimerConfig): Result => {
  const {
    offer,
    timerName,
    extraTimerName,
    timeout,
    extraTimeout,
    disabled,
    clearTimer,
  } = config
  const discountIntervalId: any = useRef()
  const extraDiscountIntervalId: any = useRef()

  const [counter, setCounter] = useState(timeout)
  const [discountActivated, setDiscountActivated] = useState(false)
  const [extraDiscount, setExtraDiscount] = useState(false)

  useEffect(() => {
    if (clearTimer) {
      localStorage.removeItem(timerName)
      localStorage.removeItem(extraTimerName)
    }

    const discountTimerEnd = localStorage.getItem(timerName)
    const extraDiscountTimerEnd = localStorage.getItem(extraTimerName)

    const continueDiscountCounter = (seconds: number): void => {
      setCounter(seconds)
      setDiscountActivated(true)
      clearInterval(discountIntervalId.current)
      discountIntervalId.current = setInterval(() => {
        setCounter((prevCount) => {
          if (prevCount > 0) {
            return prevCount - 1
          }

          setDiscountActivated(false)
          setExtraDiscount(false)
          clearInterval(discountIntervalId.current)
          return prevCount
        })
      }, 1000)
    }

    const continueExtraDiscountCounter = (seconds: number): void => {
      setCounter(seconds)
      clearInterval(extraDiscountIntervalId.current)
      extraDiscountIntervalId.current = setInterval(() => {
        setCounter((prevCount) => {
          if (prevCount > 0) {
            return prevCount - 1
          }

          setDiscountActivated(false)
          setExtraDiscount(false)
          clearInterval(extraDiscountIntervalId.current)
          return prevCount
        })
      }, 1000)
    }

    const restartCounter = (): void => {
      const timerEnd = new Date()
      timerEnd.setSeconds(timerEnd.getSeconds() + timeout)
      localStorage.setItem(timerName, timerEnd.toString())
      continueDiscountCounter(timeout)
    }

    switch (true) {
      case disabled: {
        clearInterval(discountIntervalId.current)
        clearInterval(extraDiscountIntervalId.current)
        break
      }

      case !!extraDiscountTimerEnd: {
        const nowDate = +new Date()
        const timerEndDate = +new Date(extraDiscountTimerEnd as string)
        const secondsLeft = Math.round((timerEndDate - nowDate) / 1000)

        if (secondsLeft > 0) {
          setDiscountActivated(false)
          setExtraDiscount(true)
          continueExtraDiscountCounter(secondsLeft)
        } else if (discountTimerEnd) {
          const discounttimerEndDate = +new Date(discountTimerEnd)
          const secondsLeftdiscount = Math.round(
            (discounttimerEndDate - nowDate) / 1000
          )
          if (secondsLeftdiscount > 0) {
            setExtraDiscount(false)
            setDiscountActivated(true)
            continueDiscountCounter(secondsLeftdiscount)
          } else {
            // on time per localStorage life
            setExtraDiscount(false)
            setDiscountActivated(false)
            setCounter(0)
          }
        } else {
          restartCounter()
        }
        break
      }

      case !!discountTimerEnd: {
        const nowDate = +new Date()
        const timerEndDate = +new Date(discountTimerEnd as string)
        const secondsLeft = Math.round((timerEndDate - nowDate) / 1000)
        if (secondsLeft > 0) {
          continueDiscountCounter(secondsLeft)
        } else {
          // on time per localStorage life
          setDiscountActivated(false)
          setCounter(0)
        }
        break
      }

      default: {
        restartCounter()
        break
      }
    }

    return () => {
      clearInterval(discountIntervalId.current)
      clearInterval(extraDiscountIntervalId.current)
    }
  }, [
    extraDiscount,
    extraTimeout,
    extraTimerName,
    offer,
    timeout,
    timerName,
    disabled,
    clearTimer,
  ])

  const continueDiscountCounter = (seconds: number): void => {
    setCounter(seconds)
    setDiscountActivated(true)
    clearInterval(discountIntervalId)
    discountIntervalId.current = setInterval(() => {
      setCounter((prevCount) => {
        if (prevCount > 0) {
          return prevCount - 1
        }

        setDiscountActivated(false)
        setExtraDiscount(false)
        clearInterval(discountIntervalId.current)
        return prevCount
      })
    }, 1000)
  }

  const checkDiscountTimer = (): void => {
    const savedDiscountTimerEnd = localStorage.getItem(timerName)

    if (savedDiscountTimerEnd) {
      const nowDate = +new Date()
      const timerEndDate = +new Date(savedDiscountTimerEnd as string)
      const secondsLeft = Math.round((timerEndDate - nowDate) / 1000)
      if (secondsLeft > 0) {
        setCounter(secondsLeft)
        continueDiscountCounter(secondsLeft)
      } else {
        setDiscountActivated(false)
      }
    }
  }

  const startExtraCountdown = (): void => {
    setCounter(extraTimeout)
    const timerEnd = new Date()
    timerEnd.setSeconds(timerEnd.getSeconds() + extraTimeout)
    localStorage.setItem(extraTimerName, timerEnd.toString())

    extraDiscountIntervalId.current = setInterval(() => {
      setCounter((prevCount) => {
        if (prevCount > 0) {
          return prevCount - 1
        }

        setExtraDiscount(false)
        clearInterval(extraDiscountIntervalId.current)
        checkDiscountTimer()
        return prevCount
      })
    }, 1000)
  }

  const handleActivateExtraDiscount = (): void => {
    clearInterval(discountIntervalId.current)
    if (!extraDiscount) {
      setExtraDiscount(true)
      startExtraCountdown()
    }
  }

  return {
    counter,
    discountActivated,
    extraDiscount,
    handleActivateExtraDiscount,
    continueDiscountCounter,
  }
}

export const useFormState = <S>(
  initialSate: S
): { formState: S; dispatch: Dispatch<object> } => {
  const stateReducer = (state: S, action: object): S => ({
    ...state,
    ...action,
  })

  const [formState, dispatch] = useReducer(stateReducer, initialSate)

  return { formState, dispatch }
}
