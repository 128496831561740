import { FC } from 'react'

import styles from './loader.module.scss'

interface Props {
  size?: number
  color?: string
  className?: string
}

const loaderItem = Array.from({ length: 9 }, (_, idx) => idx)

const Loader: FC<Props> = ({ size = 28, color = '#000', className = '' }) => (
  <div
    className={`${styles.loader} ${className}`}
    style={{ width: size, height: size }}
  >
    {loaderItem.map((item) => (
      <div key={item} className={styles.loader_item}>
        <span style={{ backgroundColor: color }} />
      </div>
    ))}
  </div>
)
export default Loader
