import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { ComponentType, FC, memo } from 'react'

import styles from './icon.module.scss'
import ICONS from './icons'

export type IconName = keyof typeof ICONS

export interface IconProps {
  icon: IconName
  size?: number
  className?: string
  wrapperClassName?: string
  scaled?: boolean
}

const dynamicIcon = (icon?: string): ComponentType<{ className?: string }> =>
  icon
    ? dynamic(() => import(`../../icons/ui-kit/${icon}`), {
        loading: () => null,
      })
    : () => null

const Icon: FC<IconProps> = ({
  icon,
  size = 24,
  className,
  wrapperClassName,
  scaled,
}) => {
  const IconComponent = dynamicIcon(ICONS[icon])

  const wrapperClassNames = classNames(styles.icon_wrapper, wrapperClassName)
  const iconClassNames = classNames(styles.icon, className, {
    [styles.icon_scaled]: scaled,
  })

  return (
    <i style={{ ['--size' as any]: size }} className={wrapperClassNames}>
      <IconComponent className={iconClassNames} />
    </i>
  )
}

export default memo(Icon)
